import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ApiService } from 'app/shared/api/services/api-call.service';
import { NetworkState } from 'app/shared/state/network/network.state';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';
import * as SentryAngular from '@sentry/angular-ivy';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  constructor(
    private store: Store,
    private api: ApiService,
    private http: HttpClient,
    private toastController: ToastController
  ) {}
  private version = environment.version;
  public async checkVersion(): Promise<boolean> {
    SentryAngular.captureMessage('Checking Version');
    const isBuilding = await this.checkBuildStatus();
    const isConnected = this.store.selectSnapshot(NetworkState.isConnected);
    if (!isConnected || isBuilding) {
      return false;
    }

    const { version } = await this.api._get<{ version: string }>('version/mobile');
    if (version !== this.version) {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(name => caches.delete(name)));

      if (navigator.serviceWorker) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (let registration of registrations) {
          await registration.unregister();
        }
      }
      this.presentToast('top', 'App is updating, please wait...');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      this.presentToast('top', 'App is up to date');
      return false;
    }
  }

  private async checkBuildStatus(): Promise<boolean> {
    const data = await lastValueFrom(
      this.http.get<any>('https://api.digitalocean.com/v2/apps/048cfbff-b6ac-4ed8-8b28-071945613ee9', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer dop_v1_a396a28161f012c647e955b839b9feb401ec6edb87fc33227f737711f5b1b7c6',
        },
      })
    );
    if (data.app?.in_progress_deployment?.phase) {
      this.presentToast('top', 'App is currently building, please wait...');
      return true;
    } else {
      return false;
    }
  }
  public async presentToast(position: 'top' | 'middle' | 'bottom', message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: position,
    });

    await toast.present();
  }
}

import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../types';
import { LoaderService } from 'app/shared/services/loading/loader.service';
import { lastValueFrom } from 'rxjs';

export interface ApiCall {
  name: string;
  data: any;
}
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private _http: HttpService, private loader: LoaderService) {}

  public async _post<T>(endpoint: string, body: any, customHeaders?: HttpHeaders, responseType?): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.loader.presentLoader('Please wait...');
      this._http.post(endpoint, body, customHeaders, responseType).subscribe({
        next: async event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.loader.updateLoader(`Please wait...`);
          } else if (event instanceof HttpResponse) {
            resolve(event.body);
            await this.loader.dismissLoader();
          }
        },
        error: (error: HttpErrorResponse) => {
          this.presentErrorToast(error);
          reject(error);
        },
      });
    });
  }
  public async __post<T>(endpoint: string, body: any, customHeaders?: HttpHeaders) {
    return new Promise<T>((resolve, reject) => {
      this._http.__post(endpoint, body, customHeaders).subscribe({
        next: data => {
          resolve(data as T);
        },
        error: (error: HttpErrorResponse) => {
          this.presentErrorToast(error);
          reject(error);
        },
      });
    });
  }

  public _put<T>(endpoint: string, body: any, customHeaders?: HttpHeaders) {
    return new Promise<T>((resolve, reject) => {
      this._http.put<T>(endpoint, body, customHeaders).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
          } else if (event instanceof HttpResponse) {
            this.loader.dismissLoader();
            resolve(event.body);
          }
        },
        (error: HttpErrorResponse) => {
          this.presentErrorToast(error);
          reject(error);
        }
      );
    });
  }
  public async __put<T>(endpoint: string, body: any, customHeaders?: HttpHeaders, responseType?) {
    return new Promise<T>((resolve, reject) => {
      this._http.__put(endpoint, body, customHeaders, responseType).subscribe({
        next: data => {
          resolve(data as T);
        },
        error: (error: HttpErrorResponse) => {
          this.presentErrorToast(error);
          reject(error);
        },
      });
    });
  }
  public _patch<T>(endpoint: string, body: any, customHeaders?: HttpHeaders) {
    return new Promise<T>((resolve, reject) => {
      this.loader.presentLoader('Please wait...');
      this._http.patch<T>(endpoint, body, customHeaders).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            this.loader.updateLoader(`Please wait...`);
          } else if (event instanceof HttpResponse) {
            this.loader.dismissLoader();
            resolve(event.body);
          }
        },
        (error: HttpErrorResponse) => {
          this.presentErrorToast(error);
          reject(error);
        }
      );
    });
  }
  public _delete<T>(endpoint: string, customHeaders?: HttpHeaders): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
      this._http
        .delete<ApiResponse<T>>(endpoint, customHeaders)
        .then(response => {
          this.loader.presentToast('top', `${response.message}`);
          resolve(response.data as T);
        })
        .catch((error: HttpErrorResponse) => {
          this.presentErrorToast(error);
          reject(error);
        });
    });
  }
  public async _get<T>(endpoint: string, options?: any): Promise<T> {
    try {
      const data = await lastValueFrom(this._http.get<T>(endpoint, options));
      return data;
    } catch (error) {
      this.presentErrorToast(error);
      console.error('Error:', error);
    }
  }
  private presentErrorToast(error: HttpErrorResponse) {
    if (error.status === 0) {
      const { status, message } = error;
      this.loader.presentToastError(`${status}:${message}`);
      throw new Error(`${status}:${message}`);
    } else {
      const { code, message } = error?.error ?? { code: 'Unknown', message: 'Unknown Server Error' };
      this.loader.presentToastError(`${code}:${message}`);
      throw new Error(`${code}:${message}`);
    }
  }
}
